<template>
	<div class="role-contianer">
		<div class="my-table" style="margin-top: 0;">
			<div style="display: flex;">
				<div class="invite-button"
					style="margin-left: 1.7rem; margin-bottom: .8rem;border-radius: .2rem;border: 1px solid #3B91FF;"
					@click="toEdit()">
					<img src="../../assets/uid/jiahao.png">
					新增角色
				</div>
				<div @click="copyDialog = true" class="invite-button copy"
					style="margin-left: 12px; margin-bottom: .8rem;border-radius: .2rem;">
					<img src="../../assets/role/copy.png">
					复制角色
				</div>
			</div>
			<el-table :data="tableData" style="width: 100%;" empty-text="抱歉，暂无数据！">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column fixed prop="role_name" label="角色名称" min-width="180">
				</el-table-column>
				<el-table-column fixed prop="opt" label="操作员" min-width="180">
				</el-table-column>
				<el-table-column prop="memberString" label="角色成员" min-width="120">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="202">
					<template slot-scope="scope">
						<el-button type="text" size="small" @click="toEdit(scope.row)">角色管理</el-button>
						<el-button style="color: #FF4D4F;" @click="deleteDialog = true;delRoleId = scope.row.role_id"
							type="text" size="small">
							删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="my-page">
				<el-pagination background layout="prev, pager, next, sizes, jumper" @size-change="changeSize"
					:total="total" :current-page="searchForm.page" @current-change="changePage">
				</el-pagination>
			</div>
		</div>
		<el-dialog class="my-dialog delete-dialog" title="删除角色" :visible.sync="deleteDialog" width="30%" center>
			<div class="attention">
				<img src="../../assets/attention.png">
				此操作不可逆，请谨慎确认！！
			</div>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="deleteDialog = false">取 消</el-button>
				<el-button type="danger" class="error-button" @click="commitDel()">确定删除</el-button>
			</span>
		</el-dialog>
		<el-dialog class="my-dialog" title="复制角色" :visible.sync="copyDialog" width="30%" center>
			<el-form :model="copyForm" ref="copyForm" :rules="copyRules" label-width="7.8rem">
				<el-form-item label="原角色：" prop="role_id">
					<el-select v-model="copyForm.role_id" placeholder="请选择">
						<el-option v-for="(item,index) in tableData" :label="item.role_name" :key="index"
							:value="item.role_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="新增角色名称：" prop="role_name">
					<el-input v-model="copyForm.role_name" placeholder="请输入"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="my-dialog-footer">
				<el-button @click="copyDialog = false">取 消</el-button>
				<el-button type="primary" @click="commitCopy">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import mixin from '../../mixins.js'
	import {
		list,
		deleteRole,
		copyRole
	} from '../../api/role.js'
	export default {
		mixins: [mixin],
		data() {
			return {
				deleteClientDialog: false,
				deleteDialog: false,
				delRoleId: "",
				copyDialog: false,
				copyForm: {},
				copyRules: {
					role_id: [{
						required: true,
						message: '请选择',
						trigger: 'change'
					}],
					role_name: [{
						required: true,
						message: '请输入',
						trigger: 'change'
					}]
				}
			}
		},
		computed: {

		},
		methods: {
			async getData() {
				let res = (await list(this.searchForm)).data
				this.tableData = res.records
				this.tableData = this.tableData.map(e => {
					e.memberString = e.members.join(',')
					return e
				})
				this.total = res.total_count
			},
			async commitDel() {
				await deleteRole({
					role_id: this.delRoleId
				})
				this.$message.success('删除成功')
				this.deleteDialog = false
				this.getData()
			},
			async commitCopy() {
				this.$refs["copyForm"].validate(async (valid) => {
					if (valid) {
						await copyRole(this.copyForm)
						this.$message.success('复制成功')
						this.copyDialog = false
						this.getData()
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			toEdit(row) {
				if (row) {
					this.$router.push({
						path: '/add_role',
						query: {
							role_id: row.role_id,
							from: this.$route.path,
							fromName: this.$route.meta.title,
							edit: true
						}
					})
				} else {
					this.$router.push({
						path: '/add_role',
						query: {
							from: this.$route.path,
							fromName: this.$route.meta.title
						}
					})
				}
			}
		}
	}
</script>

<style lang="scss">
	.role-contianer {
		.copy {
			background-color: #ffffff;
			color: #3B91FF;
			border: 1px solid #3B91FF;
		}

		.delete-dialog {
			.el-dialog__body {
				padding: 0.3rem 2.7rem 3.6rem 1rem;
			}


		}

		.attention {
			margin-top: 2.5rem;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: .8rem;
				height: .8rem;
				margin-right: .4rem;
			}
		}

		.error-button {
			background-color: #FF4D4F;
			border-color: #FF4D4F;
			color: #ffffff;
		}
	}
</style>
