import request from '../request.js'

export const list = data => {
	console.log(data)
	return request({
		url: "/role/list/",
		method: "get",
		params: data
	})
}
export const permissionList = () => {
	return request({
		url: "/permission/list/",
		method: "get",
		params: {}
	})
}

export const addRoles = data => {
	return request({
		url: "/role/add/",
		method: 'post',
		data,
		// type:'json'
	})
}

export const deleteRole = data => {
	return request({
		url: "/role/delete/",
		method: "post",
		data
	})
}

export const detail = role_id => {
	return request({
		url:"/role/detail/",
		method:"get",
		params:{role_id}
	})
}


export const update = data=>{
	return request({
		url:"/role/update/",
		method:"post",
		data
	})
}

export const copyRole = data=>{
	return request({
		url:"/role/copy/",
		method:"post",
		data
	})
}