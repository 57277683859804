const mixins = {
	data() {
		return {
			searchForm: {
				page:1,
				page_size:10
			},
			tableData: [],
			total:0
		}
	},
	methods: {
		changePage(e) {
			console.log(e)
			this.searchForm.page = e
			this.getData()
		},
		changeSize(e){
			this.searchForm.page_size = e
			this.getData()
		},
		getDataSearch(){
			this.searchForm.page = 1
			this.getData()
		}
	},
	created() {
		this.getData()
	}
}

export default mixins
